// extracted by mini-css-extract-plugin
export var backdrop = "index-module--backdrop--NJQCM";
export var button = "index-module--button--D1uvH";
export var collapsed = "index-module--collapsed--hTwWZ";
export var collapsing = "index-module--collapsing--o3Sp0";
export var dialog = "index-module--dialog---LGf0";
export var expanded = "index-module--expanded--hdFPg";
export var expanding = "index-module--expanding--57lpJ";
export var heading = "index-module--heading--xfdwX";
export var item = "index-module--item--Tll2R";
export var menu = "index-module--menu--CmAk9";